import HomePage from "../components/pages/HomePage/HomePage";
import AboutPage from "../components/pages/AboutPage/AboutPage";
import GalleryPage from "../components/pages/GalleryPage/GalleryPage";
import ContactPage from "../components/pages/ContactPage/ContactPage";
import NotFoundPage from "../components/pages/NotFoundPage";
import React from "react";

export type SubMenuItem = {
  path: string;
  key: string;
};

export type RouteLocation = "header" | "footer" | "both" | "none";

export type RouteConfig = {
  path: string;
  component: React.ComponentType;
  key: string;
  location: RouteLocation;
  subMenu?: SubMenuItem[];
};

const routesConfig: RouteConfig[] = [
  { path: "/", component: HomePage, key: "home", location: "header" },
  { path: "/about", component: AboutPage, key: "about", location: "header" },
  { path: "/gallery", component: GalleryPage, key: "gallery", location: "header" },
  { path: "/contact", component: ContactPage, key: "contact", location: "header" },
  { path: "*", component: NotFoundPage, key: "notFound", location: "none" },
];

export default routesConfig;