import React from "react";
import { useTranslation } from "react-i18next";
import languagesConfig from "../../../../i18n/languagesConfig";
import styled from "styled-components";

interface LanguageButtonProps {
  active: boolean;
}

const LanguageSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const LanguageButton = styled.button<LanguageButtonProps>`
  cursor: pointer;
  padding: 5px;
  border: none;
  background: none;

  svg {
    width: 24px;
    height: auto;
    transition: opacity 0.3s ease;
    ${({ active }) => (active ? `opacity: 1;` : `opacity: 0.5;`)}
  }
`;

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleChangeLanguage = async (lang: string) => {
    try {
      await i18n.changeLanguage(lang);
      localStorage.setItem("i18nextLng", lang);
    } catch (error) {
      console.error("Error changing language:", error);
    }
  };

  return (
    <LanguageSelectorContainer>
      {Object.entries(languagesConfig).map(([key, { label, flag }]) => (
        <LanguageButton
          key={key}
          onClick={() => handleChangeLanguage(key)}
          aria-label={`Switch to ${label}`}
          active={currentLanguage.startsWith(key)}
        >
          {flag}
        </LanguageButton>
      ))}
    </LanguageSelectorContainer>
  );
};

export default LanguageSelector;
