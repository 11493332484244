import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';

import image2 from '../../../assets/images/2.jpeg';
import image3 from '../../../assets/images/3.jpeg';
import image4 from '../../../assets/images/4.jpeg';
import image5 from '../../../assets/images/5.jpeg';
import image6 from '../../../assets/images/6.jpeg';
import image7 from '../../../assets/images/7.jpeg';
import image8 from '../../../assets/images/8.jpeg';
import image9 from '../../../assets/images/9.jpeg';
import image10 from '../../../assets/images/10.jpeg';
import image11 from '../../../assets/images/11.jpeg';
import image12 from '../../../assets/images/12.jpeg';
import image13 from '../../../assets/images/13.jpeg';
import image14 from '../../../assets/images/14.jpeg';
import image15 from '../../../assets/images/15.jpeg';
import image16 from '../../../assets/images/16.jpeg';
import image17 from '../../../assets/images/17.jpeg';
import image18 from '../../../assets/images/18.jpeg';
import image19 from '../../../assets/images/19.jpeg';
import image20 from '../../../assets/images/20.jpeg';
import image21 from '../../../assets/images/21.jpeg';
import image22 from '../../../assets/images/22.jpeg';
import image23 from '../../../assets/images/23.jpeg';
import image24 from '../../../assets/images/24.jpeg';
import image25 from '../../../assets/images/25.jpeg';
import image26 from '../../../assets/images/26.jpeg';
import image27 from '../../../assets/images/27.jpeg';
import image28 from '../../../assets/images/28.jpeg';
import image29 from '../../../assets/images/29.jpeg';
import image30 from '../../../assets/images/30.jpeg';

const GlobalStyle = createGlobalStyle`
  .srl__image {
    max-height: 80vh;
  }
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 15px;
  padding: 20px;
`;

const ImageWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;

  @media (hover: hover) {
    &:hover .caption {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
`;

const Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  transition: opacity 0.3s;
  text-align: center;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;

  // Visibilité par défaut sur les appareils mobiles
  @media (max-width: 768px) {
    opacity: 1;
    visibility: visible;
  }
`;

const StyledTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  color: white;
  padding-top: 100px;
  margin-bottom: 2rem;
`;

const GalleryPage = () => {
    const { t } = useTranslation();

    const images = [
        { src: image2, captionKey: 'gallery.captions.0' },
        { src: image3, captionKey: 'gallery.captions.1' },
        { src: image4, captionKey: 'gallery.captions.2' },
        { src: image5, captionKey: 'gallery.captions.3' },
        { src: image6, captionKey: 'gallery.captions.4' },
        { src: image7, captionKey: 'gallery.captions.5' },
        { src: image8, captionKey: 'gallery.captions.6' },
        { src: image9, captionKey: 'gallery.captions.7' },
        { src: image10, captionKey: 'gallery.captions.8' },
        { src: image11, captionKey: 'gallery.captions.9' },
        { src: image12, captionKey: 'gallery.captions.10' },
        { src: image13, captionKey: 'gallery.captions.11' },
        { src: image14, captionKey: 'gallery.captions.12' },
        { src: image15, captionKey: 'gallery.captions.13' },
        { src: image16, captionKey: 'gallery.captions.14' },
        { src: image17, captionKey: 'gallery.captions.15' },
        { src: image18, captionKey: 'gallery.captions.16' },
        { src: image19, captionKey: 'gallery.captions.17' },
        { src: image20, captionKey: 'gallery.captions.18' },
        { src: image21, captionKey: 'gallery.captions.19' },
        { src: image22, captionKey: 'gallery.captions.20' },
        { src: image23, captionKey: 'gallery.captions.21' },
        { src: image24, captionKey: 'gallery.captions.22' },
        { src: image25, captionKey: 'gallery.captions.23' },
        { src: image26, captionKey: 'gallery.captions.24' },
        { src: image27, captionKey: 'gallery.captions.25' },
        { src: image28, captionKey: 'gallery.captions.26' },
        { src: image29, captionKey: 'gallery.captions.27' },
        { src: image30, captionKey: 'gallery.captions.28' }
    ];

    return (
        <>
            <GlobalStyle />
            <StyledTitle>{t('gallery.title')}</StyledTitle>
            <GalleryContainer>
                {images.map((image, index) => (
                    <ImageWrapper
                        key={index}
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Image src={image.src} alt={`Gallery item ${index + 1}`} />
                        <Caption className="caption">{t(image.captionKey)}</Caption>
                    </ImageWrapper>
                ))}
            </GalleryContainer>
        </>
    );
};

export default GalleryPage;
