import { ThemeProvider, createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Playfair Display', serif; // Elegant typeface for luxury feel
    background: ${({ theme }) => theme.colors.quaternary};
    color: ${({ theme }) => theme.colors.tertiary};
    line-height: 1.6;
  }
`;

const theme = {
    colors: {
        primary: "#000000",
        secondary: "#C83264",
        tertiary: "#7f8c8d",
        quaternary: "#eaecee"
    }
};

const Container = styled.div`
  text-align: center;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Title = styled(motion.h1)`
  color: ${props => props.theme.colors.primary};
  font-size: 4.5rem;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.h2`
  color: ${props => props.theme.colors.secondary};
  font-size: 2.5rem;
  margin-bottom: 1em;
`;

const Description = styled(motion.p)`
  color: ${props => props.theme.colors.tertiary};
  font-size: 1.6rem;
  max-width: 850px;
  margin-bottom: 2em;
  opacity: 0.9;
`;

const ContactButton = styled(motion.button)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.quaternary};
  font-size: 1.3rem;
  padding: 18px 36px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.primary};
  }
`;

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Container>
                <Helmet>
                    <title>{t('homePage.title')}</title>
                    <meta name="description" content={t('homePage.description')} />
                </Helmet>
                <Title
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, type: 'spring', stiffness: 120 }}
                >
                    {t('homePage.welcomeTitle')}
                </Title>
                <Subtitle>{t('homePage.subtitle')}</Subtitle>
                <Description
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.8 }}
                >
                    {t('homePage.description')}
                </Description>
                <ContactButton
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => window.location.href = 'mailto:contact@example.com'}
                >
                    {t('homePage.contactMe')}
                </ContactButton>
            </Container>
        </ThemeProvider>
    );
};

export default HomePage;
