import React, { useState, useEffect } from "react";
import DesktopNav from "./desktop/DesktopNav";
import MobileNav from "./mobile/MobileNav";
import useWindowSize from "../../../hooks/useWindowSize";
import { ThemeProvider } from "styled-components";
import { baseTheme } from "../../../theme/Theme";

const Header: React.FC = () => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState<boolean>(width < 768);
  const headerTheme = baseTheme.getHeaderTheme();

  useEffect(() => {
    setIsMobile(width < 768);
  }, [width]);

  return (
    <ThemeProvider theme={headerTheme}>
      <header aria-label="Main navigation">
        {isMobile ? <MobileNav /> : <DesktopNav />}
      </header>
    </ThemeProvider>
  );
};

export default Header;
