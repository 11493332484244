import React from "react";

const FrenchFlagSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1235 650">
    <path fill="#00267f" d="M0 0h411.67v650H0z" />
    <path fill="#fff" d="M411.67 0h411.66v650H411.67z" />
    <path fill="#f31830" d="M823.33 0h411.67v650H823.33z" />
  </svg>
);

export default FrenchFlagSvg;
