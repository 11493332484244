

export const baseTheme = {
  colors: {
    primary: "#000000",
    secondary: "#C83264",
    tertiary: "#7f8c8d", 
    quaternary: "#eaecee",
  },
  shadows: {
    textShadow: "0 3px 6px rgba(0, 0, 0, 0.6)",
    buttonShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    imageShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
  },
 
  getPrivacyPolicyThemeTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
       
        background: this.colors.quaternary,
        text: this.colors.primary,
        link: this.colors.secondary,
        linkHover: this.colors.tertiary,
        heading: this.colors.secondary,
        subHeading: this.colors.tertiary,
      },
     
    };
  },

 
  getConditionsOfUseTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
       
        background: "rgba(255, 255, 255, 0.9)",
        text: this.colors.tertiary,
        link: this.colors.primary,
        linkHover: this.colors.secondary,
        heading: this.colors.primary,
        subHeading: this.colors.secondary,
      },
     
    };
  },

 
  getLegalNoticeTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
       
        background: this.colors.primary,
        text: this.colors.quaternary,
        link: this.colors.tertiary,
        linkHover: this.colors.secondary,
        heading: this.colors.quaternary,
        subHeading: this.colors.secondary,
      },
     
    };
  },
  getHeaderTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
        headerBackground: this.colors.primary,
        headerText: this.colors.quaternary,
        link: this.colors.secondary,
        linkHover: this.colors.tertiary,
        logoText: this.colors.secondary,
        logoSpanText: this.colors.quaternary,
        navLinkText: this.colors.quaternary,
        navLinkActive: this.colors.secondary,
        navLinkHover: this.colors.secondary,
      },
    };
  },
  getFooterTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
        background: this.colors.quaternary,
        text: this.colors.primary,
        icon: this.colors.quaternary,
        link: this.colors.secondary,
        linkHover: this.colors.tertiary,
        socialIconBackground: this.colors.secondary,
        socialIconHover: this.colors.primary,
        divider: this.colors.tertiary,
      },
    };
  },
  getHomePageTheme: function () {
    return {
      ...this,
      colors: {
        ...this.colors,
       
        imageSectionTitle: this.colors.secondary,
        imageSectionSubtitle: this.colors.tertiary,
        callToActionBackground: this.colors.primary,
        callToActionText: this.colors.quaternary,
        titleColor: this.colors.secondary,
        textColor: this.colors.tertiary,
        buttonStartColor: this.colors.primary,
        buttonEndColor: this.colors.secondary,
        buttonTextColor: this.colors.quaternary,
        gradientStart: "rgba(240, 240, 240, 0.85)",
        gradientEnd: "rgba(255, 255, 255, 0.85)",
        JobSectionPrimary: this.colors.secondary,
        JobSectionSecondary: this.colors.primary,
        JobSectionText: this.colors.tertiary,
        JobSectionBackground: this.colors.quaternary,
        ourApplicationsBackground: this.colors.secondary,
        ourApplicationsNavButton: this.colors.primary,
        ourApplicationsNavButtonHover: this.colors.secondary,
        ourApplicationsDot: this.colors.tertiary,
        ourApplicationsActiveDot: this.colors.secondary,
        slideComponentBackground: this.colors.quaternary,
        slideComponentText: this.colors.primary,
        slideComponentSubtitle: this.colors.primary,
        slideComponentButton: this.colors.primary,
        slideComponentButtonHover: this.colors.secondary,
        slideComponentIndicator: this.colors.primary,
        slideComponentIndicatorSoon: this.colors.secondary,
      },
      shadows: {
        ...this.shadows,
        ourApplicationsNavButtonShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        slideComponentBoxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)",
        slideComponentNavButtonShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
      },
      gradients: {
        buttonGradient: `linear-gradient(145deg, ${this.colors.primary}, ${this.colors.secondary})`,
        JobSectionButtonGradient: `linear-gradient(145deg, ${this.colors.primary}, ${this.colors.secondary})`,
      },
     
    };
  },
};
