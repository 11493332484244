import React, { useState, FormEvent } from 'react'; // Import FormEvent for TypeScript
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { baseTheme } from "../../../theme/Theme";

const FullPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.quaternary};
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.secondary};
  margin-bottom: 2rem;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 8px;
  background-color: ${props => props.theme.colors.quaternary};
  color: ${props => props.theme.colors.primary};
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 8px;
  height: 150px;
  background-color: ${props => props.theme.colors.quaternary};
  color: ${props => props.theme.colors.primary};
`;

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 8px;
  background-color: ${props => props.theme.colors.quaternary};
  color: ${props => props.theme.colors.primary};
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.quaternary};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const ContactPage: React.FC = () => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [reason, setReason] = useState('Private Meeting');

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        const mailtoLink = `mailto:temptation_4_you@yahoo.com?subject=${encodeURIComponent(`Arthur Tattoo - ${t(`contactPage.reasons.${reason.toLowerCase()}`)}`)}&body=${encodeURIComponent(message)}%0A%0A---%0ANom: ${encodeURIComponent(name)}%0AEmail: ${encodeURIComponent(email)}`;
        window.location.href = mailtoLink;
    };

    return (
        <ThemeProvider theme={baseTheme}>
            <FullPageContainer>
                <Title>{t('contactPage.title')}</Title>
                <ContactForm onSubmit={handleFormSubmit}>
                    <Input type="text" placeholder={t('contactPage.yourName')} value={name} onChange={e => setName(e.target.value)} required />
                    <Input type="email" placeholder={t('contactPage.yourEmail')} value={email} onChange={e => setEmail(e.target.value)} required />
                    <Select value={reason} onChange={e => setReason(e.target.value)}>
                        <option value="Private Meeting">{t('contactPage.reasons.privateMeeting')}</option>
                        <option value="Special Request">{t('contactPage.reasons.specialRequest')}</option>
                        <option value="General Inquiry">{t('contactPage.reasons.generalInquiry')}</option>
                        <option value="Booking">{t('contactPage.reasons.booking')}</option>
                    </Select>
                    <TextArea placeholder={t('contactPage.yourMessage')} value={message} onChange={e => setMessage(e.target.value)} required />
                    <SubmitButton type="submit">{t('contactPage.send')}</SubmitButton>
                </ContactForm>
            </FullPageContainer>
        </ThemeProvider>
    );
};

export default ContactPage;
