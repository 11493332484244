import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t("notFoundPage.title")}</h1>
      <p>{t("notFoundPage.description")}</p>
    </div>
  );
};

export default NotFoundPage;
