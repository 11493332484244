import React from "react";
const EnglishFlagSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="#FFF"
    viewBox="0 0 1235 650"
  >
    <path d="M0 0h1235v650H0" />
    <path
      stroke="#b22234"
      strokeDasharray="50"
      strokeWidth="2470"
      d="M0 0v651"
    />
    <path fill="#3c3b6e" d="M0 0h494v350H0" />
    <g id="e">
      <g id="d">
        <g id="f">
          <g id="c">
            <g id="b">
              <path id="a" d="m30 50.6 12-36 12 36-30.8-22H61" />
              <use xlinkHref="#a" x="82" />
            </g>
            <use xlinkHref="#b" x="164" />
            <use xlinkHref="#a" x="328" />
          </g>
          <use xlinkHref="#a" x="410" />
        </g>
        <use xlinkHref="#c" x="41" y="35" />
      </g>
      <use xlinkHref="#d" y="70" />
    </g>
    <use xlinkHref="#e" y="140" />
    <use xlinkHref="#f" y="280" />
  </svg>
);

export default EnglishFlagSvg;
