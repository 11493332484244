import React from "react";

const GermanFlagSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 600">
    <path fill="#FFCE00" d="M0 0h900v600H0z" />
    <path fill="#DD0000" d="M0 0h900v400H0z" />
    <path fill="#000" d="M0 0h900v200H0z" />
  </svg>
);

export default GermanFlagSvg;
