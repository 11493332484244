import styled, { ThemeProvider, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import profilePic from '../../../assets/images/profile.jpeg';

// Thèmes et animations
const baseTheme = {
    colors: {
        primary: "#FFFFFF",
        secondary: "#C83264",
        tertiary: "#7f8c8d",
        quaternary: "#000000",
        accent: "#e74c3c",
    }
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const rotateAndScale = keyframes`
  0% { transform: rotate(0deg) scale(1); }
  50% { transform: rotate(360deg) scale(1.2); }
  100% { transform: rotate(0deg) scale(1); }
`;

const FullPageLayout = styled.div`
  text-align: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.quaternary};
  animation: ${fadeIn} 2s ease-out;
  padding: 0;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 3rem;
  text-shadow: 2px 2px 4px ${({ theme }) => theme.colors.tertiary};
`;

const ProfilePic = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  animation: ${pulse} 3s infinite ease-in-out;
`;

const SubHeading = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  font-size: 1.7rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  max-width: 80%;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const IconLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.tertiary};
    animation: ${rotateAndScale} 2s infinite;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
`;

const AboutPage = () => {
    const { t } = useTranslation();

    return (
        <ThemeProvider theme={baseTheme}>
            <FullPageLayout>
                <Heading>{t('aboutPage.title')}</Heading>
                <ProfilePic src={profilePic} alt={t('aboutPage.profileAlt')} />
                <SubHeading>{t('aboutPage.subtitle')}</SubHeading>
                <Text>{t('aboutPage.description')}</Text>
                <Button>{t('aboutPage.contactButton')}</Button>
                <IconContainer>
                    <IconLink href="https://twitter.com/ArthurTattoo">
                        <Icon icon={faTwitter} />
                    </IconLink>
                </IconContainer>
            </FullPageLayout>
        </ThemeProvider>
    );
};

export default AboutPage;
