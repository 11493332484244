import EnglishFlagSvg from "../assets/Flags/EnglishFlagSvg";
import FrenchFlagSvg from "../assets/Flags/FrenchFlagSvg";
import GermanFlagSvg from "../assets/Flags/GermanFlagSvg";

interface LanguageConfig {
  flag: JSX.Element;
  label: string;
}

const languagesConfig: Record<string, LanguageConfig> = {
  en: {
    label: "English",
    flag: <EnglishFlagSvg />,
  },
  fr: {
    label: "Français",
    flag: <FrenchFlagSvg />,
  },
  de: {
    label: "Deutsch",
    flag: <GermanFlagSvg />,
  },
};

export default languagesConfig;
